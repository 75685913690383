<template>
  <div class="page-body-box">
    <PageNavBack :primaryText="primaryText + '带积分的培训任务 - '+ '撤回任务'" showBack @goBack="goBack"/>
    <div class="content-box">
      <template>
        <div class="table-title">
          <el-row>
            <el-col :span="4">
              <el-input
                :placeholder="'搜索培训活动名称'"
                @input="onInputChange"
                clearable=""
                v-model="searchData.programName">
                <i slot="suffix" class="el-input__icon el-icon-search hand" @click="onSearch"></i>
              </el-input>
            </el-col>
            <el-col :span="12" :offset="8">
              <div style="text-align:right;">
                <span>累计可撤回：{{ withdrawMoney || 0 }}</span>
              </div>
            </el-col>
          </el-row>
        </div>
        <template>
          <el-table v-loading="loading"
                    :data="withdrawCodeTable"
                    border
                    style="width: 100%">
            <el-table-column
              :show-overflow-tooltip='true'
              min-width="140"
              prop="programName"
              align="left"
              label="培训活动名称">
              <template slot-scope="scope">
                <span>{{ scope.row.programName || '—' }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop=""
              label="活动时间"
              align="center"
              min-width="140">
              <template slot-scope="scope">
                <span>{{ ($moment(scope.row.startTime).format('YYYY-MM-DD') + '——' + $moment(scope.row.endTime).format('YYYY-MM-DD')) || '-' }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="allMoney"
              label="总投入积分"
              align="center"
              min-width="80">
              <template slot-scope="scope">
                <span>{{ scope.row.allMoney || '0' }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="getMoney"
              label="已领取积分"
              align="center"
              min-width="80">
              <template slot-scope="scope">
                <span>{{ scope.row.getMoney || '0' }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="unGetMoney"
              label="未领取积分"
              align="center"
              min-width="80">
              <template slot-scope="scope">
                <span>{{ scope.row.unGetMoney || '0' }}</span>
              </template>
            </el-table-column>
            <el-table-column
              fixed="right"
              label="操作"
              align="center"
              min-width="80">
              <template slot-scope="scope">
                <el-button @click="handleClick(scope.row)" type="text" size="small">撤回</el-button>
              </template>
            </el-table-column>
          </el-table>
        </template>
      </template>
      <el-pagination class="sorter-box"
                     @current-change="onCurrentChange"
                     :current-page="searchData.pageNo"
                     :page-size="searchData.pageSize"
                     :total="total"
                     background
                     hide-on-single-page
                     layout="total, prev, pager, next, jumper"
      ></el-pagination>
    </div>
    <WithdrawResources
      :visible="dialogFormVisible"
      :programId="programId"
      :sceneType="sceneType"
      @onClose="onRecallSceneCodeModalClose"/>
  </div>
</template>

<script>
import PageNavBack from '@/bizComponents/PageNavBack'
import WithdrawResources from '@/bizComponents/WithdrawResources'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'WithDrawTrain',
  components: {
    PageNavBack,
    WithdrawResources
  },
  computed: {
    ...mapGetters(['withdrawCodeTable']),
    // initSearchData(){
    //   return JSON.stringify(this.searchData);
    // }
  },
  // watch:{
  //   initSearchData:{
  //     // handler(newVal,oldVal){
  //     //   console.log(newVal,oldVal)
  //     //   if(JSON.parse(newVal).pageNo === JSON.parse(oldVal).pageNo){
  //     //     this.$set(this.searchData,"pageNo",1)
  //     //   }
  //     //   clearTimeout(this.timerId);
  //     //   this.timerId = setTimeout(() => {
  //     //     this.onGetRecallCodeTable()
  //     //   },500)
  //     // },
  //     deep: true
  //   }
  // },
  data() {
    return {
      primaryText: '财务管理 - ',
      // -------------------- 搜索、页数 -----------------
      loading: true,
      timerId: null,
      total: 0,
      searchData: {
        pageNo: 1,
        pageSize: 10,
        programName: '',
      },
      delay: null,
      flag: true,
      // ------------------------- dialog ----------------
      dialogFormVisible: false,
      programId: null,
      sceneType: 'training',
      withdrawMoney: 0
    }
  },
  mounted() {
    this.onPageInit()
    this.onGetRecallCodeTable()
  },
  methods: {
    ...mapActions(['getWithdrawList', 'getWithdrawMoney']),
    onPageInit() {
      this.getWithdrawMoney().then(res => {
          this.withdrawMoney = res.data
        }
      )
    },
    goBack() {
      this.$router.go(-1)
    },
    onInputChange() {
      this.delay && clearTimeout(this.delay)
      this.delay = setTimeout(() => {
        this.delay && clearTimeout(this.delay)
      }, 1000)
    },
    onGetRecallCodeTable() {
      this.loading = true
      this.getWithdrawList(this.searchData).then(res => {
        console.log('res',res)
        this.total = res.data.total || 0
        this.loading = false
      }, rea => {
        this.loading = false
      })
    },
    onSearch() {
      if (this.searchData.pageNo === 1) {
        this.onGetRecallCodeTable()
      }
      this.$set(this.searchData, 'pageNo', 1)
    },
    onCurrentChange(options) {
      this.$set(this.searchData, 'pageNo', options)
      this.onGetRecallCodeTable()
    },
    handleClick(row) {
      this.dialogFormVisible = true
      this.programId = row.programId
    },
    onRecallSceneCodeModalClose() {
      this.dialogFormVisible = false
      this.onPageInit()
      this.onGetRecallCodeTable()
    },
  }
}
</script>

<style scoped>
.content-box {
  height: 100%;
  margin: 30px 30px 30px 30px;
  padding: 30px 30px 30px 30px;
  background: #ffffff;
}

.table-title {
  color: #7784A1;
  padding-bottom: 17px;
}

.hand {
  cursor: hand;
  cursor: pointer;
}

.sorter-box {
  padding: 30px;
  text-align: right;
  background: #ffffff;
}
</style>
